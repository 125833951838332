export const convertEventLocationType = (eventLocationType: string): string => {
  switch (eventLocationType) {
    case "Online":
      return "online";
    case "Physical":
      return "physical";
    case "Hybrid":
      return "hybrid";
    default:
      return `UNKNOWN: ${eventLocationType}`;
  }
};
