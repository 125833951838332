export const convertEventTypes = (eventType: string[]): string[] => {
  return eventType.map((type) => {
    switch (type) {
      case "Conference":
        return "meetconferences";
      case "Seminar/talk":
        return "seminar";
      case "Workshop":
        return "workshop";
      case "Networking event":
        return "networking";
      case "Pitch event":
        return "startuppitchevent";
      case "Hackathon":
        return "startuphackathon";
      case "Job fair":
        return "localjobs";
      default:
        return `UNKNOWN: ${type}`;
    }
  });
};
