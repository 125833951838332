export const convertTopics = (topics: string[]): string[] => {
  return topics.map((topic) => {
    switch (topic) {
      case "For founders":
        return "bizstartup";
      case "For tech talent":
        return "bizlabour";
      case "Fundraising":
        return "startupfunding";
      case "Hiring":
        return "bizsettingup";
      case "Marketing":
        return "bizmarketingsales";
      case "Internationalisation":
        return "startupsupport";
      case "Diversity & Inclusion":
        return "startupdiversity";
      case "Emerging technologies":
        return "bizinnovation";
      case "Other":
        return "other";
      default:
        return `UNKNOWN: ${topic}`;
    }
  });
};
