import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loader from "@feed-factory/feed-factory.loader";
import translationService from "@feed-factory/feed-factory.translation";
import { Suspense } from "react";
import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";
import { BrowserRouter } from "react-router-dom";

// TODO: REPLACE WITH RESTRICTED TOKEN
const authToken = "eKJhkYyE8a-KnWPOMZRff-KZz3ERJ0a2-toYQZqEjON";

feedFactoryAxios.defaults.baseURL = "https://app.thefeedfactory.nl/api";
feedFactoryAxios.defaults.headers.common[
  "Authorization"
] = `Bearer ${authToken}`;

translationService.changeLanguage("en");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
