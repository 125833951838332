import React, { useState } from "react";
import classes from "./App.module.scss";
import { Controller, useForm } from "react-hook-form";
import RadioButtonSelector from "./components/RadioButtonSelector/RadioButtonSelector";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MediaWidget from "@feed-factory/feed-factory.media-widget";
import SingleDatesWidget from "@feed-factory/feed-factory.single-dates-widget";
import LocationSelector from "@feed-factory/feed-factory.location-selector";
import { Lang } from "@feed-factory/feed-factory.models.lang";
import { withRouter } from "react-router-dom";
import AddressWidget from "@feed-factory/feed-factory.address-widget";
import { postNewEvent } from "./helpers/postNewEvent";
import { FormValues } from "./models/FormValues";
import { LocationSelectionType } from "./models/LocationSelectionType";
import { IntFileItem } from "@feed-factory/feed-factory.models.ndtrc";
import CheckboxSelector from "./components/CheckboxSelector/CheckboxSelector";
import "quill-paste-smart";

function App() {
  const formMethods = useForm<FormValues>({
    defaultValues: {
      dates: [],
    },
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    watch,
  } = formMethods;

  const [locationSelectionType, setLocationSelectionType] =
    useState<LocationSelectionType>(LocationSelectionType.RegisteredLocation);

  const eventLocationType = watch("eventLocationType");

  const onSubmit = (data: any) => {
    postNewEvent(
      data,
      eventLocationType === "Online" ? null : locationSelectionType
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // console.warn("ERRORS", errors); // DEBUG

  let locationSelectionWidget;
  switch (eventLocationType) {
    case null:
      locationSelectionWidget = (
        <p className={classes.eventLocationInfoBox}>
          Please select the type of event first
        </p>
      );
      break;
    case "Online":
      locationSelectionWidget = (
        <p className={classes.eventLocationInfoBox}>
          Please add the event website in the Website section
        </p>
      );
      break;
    default:
      if (locationSelectionType === LocationSelectionType.RegisteredLocation) {
        locationSelectionWidget = (
          <Controller
            control={control}
            name="locationItem"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <LocationSelector
                value={value?.id || null}
                lang={Lang.EN}
                filterOnUserOrganisations={["Amsterdam Marketing"]}
                onSelect={onChange}
                minimalCardDisplay
                myUserOrganisations={[]}
              />
            )}
            defaultValue={null}
          />
        );
      } else if (
        locationSelectionType === LocationSelectionType.ManualLocation
      ) {
        locationSelectionWidget = (
          <div className={classes.addressWidget}>
            <label>Location name</label>
            <input
              type="text"
              {...register("locationLabel", { required: true, maxLength: 256 })}
              className={classes.locationLabelInput}
            />
            <AddressWidget
              namespace="locationAddress."
              formMethods={formMethods}
              hideCountryField
            />
          </div>
        );
      }
  }

  return (
    <div className={classes.main}>
      <header className="">
        <img
          className={classes.logo}
          alt={"Startup Amsterdam & I Amsterdam"}
          src={"/images/iamsterdamstartup.png"}
        />
      </header>
      <section>
        <h2 className={classes.heading}>Instructions</h2>
        <div className={classes.body}>
          <p>
            Please note that the StartupAmsterdam Event Calendar is only meant
            for online and in-person events, focused on and benefiting the
            Amsterdam Metropolitan Area’s startup and tech community.
          </p>
        </div>
      </section>
      {!isSubmitSuccessful && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <h2 className={classes.heading}>What</h2>
            <div className={classes.body}>
              <div className={classes.category}>
                <label>Type of event</label>
                <CheckboxSelector
                  namespace={"typeOfEvent"}
                  options={[
                    "Conference",
                    "Seminar/talk",
                    "Workshop",
                    "Networking event",
                    "Pitch event",
                    "Hackathon",
                    "Job fair",
                  ]}
                  register={register}
                />
                {errors.typeOfEvent && (
                  <div className={classes.formError}>
                    Type of event is required
                  </div>
                )}
                <label>Topic(s)</label>
                <CheckboxSelector
                  namespace={"topics"}
                  options={[
                    "For founders",
                    "For tech talent",
                    "Fundraising",
                    "Hiring",
                    "Marketing",
                    "Internationalisation",
                    "Diversity & Inclusion",
                    "Emerging technologies",
                    "Other",
                  ]}
                  register={register}
                />
                {errors.topics && (
                  <div className={classes.formError}>Topic is required</div>
                )}
                <label>Title of event</label>
                <input
                  type="text"
                  {...register("title", { required: true, maxLength: 256 })}
                />
                {errors.title && (
                  <div className={classes.formError}>
                    First name is required
                  </div>
                )}
                <label>Short description (in English)</label>
                <textarea
                  {...register("shortDescription", {
                    required: true,
                  })}
                />
                {errors.shortDescription && (
                  <div className={classes.formError}>
                    Short description is required
                  </div>
                )}
                <label>Long description (in English)</label>
                <Controller
                  name={"longDescription"}
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <div className={classes.quillContainer}>
                      <ReactQuill
                        onChange={(value) => {
                          onChange(value);
                        }}
                        value={value}
                        ref={ref}
                      />
                    </div>
                  )}
                />
                {errors.longDescription && (
                  <div className={classes.formError}>
                    Long description is required
                  </div>
                )}
              </div>
            </div>
          </section>

          <section>
            <h2 className={classes.heading}>Where</h2>
            <div className={classes.body}>
              <div className={classes.category}>
                <label>Location Type</label>
                <RadioButtonSelector
                  namespace={"eventLocationType"}
                  options={["Online", "Physical", "Hybrid"]}
                  register={register}
                />
                {errors.eventLocationType && (
                  <div className={classes.formError}>
                    Location type is required
                  </div>
                )}
              </div>

              <div className={classes.locationSelectionWidget}>
                {eventLocationType && eventLocationType === "Hybrid" && (
                  <p className={classes.eventLocationInfoBox}>
                    For online participation, please add the event website in
                    the Website section
                  </p>
                )}
                {locationSelectionWidget}
              </div>

              {eventLocationType && eventLocationType !== "Online" && (
                <button
                  type="button"
                  className={classes.toggleLocationSelectionTypeButton}
                  onClick={() => {
                    setLocationSelectionType(
                      locationSelectionType ===
                        LocationSelectionType.ManualLocation
                        ? LocationSelectionType.RegisteredLocation
                        : LocationSelectionType.ManualLocation
                    );
                  }}
                >
                  {locationSelectionType ===
                  LocationSelectionType.ManualLocation
                    ? "Select a registered location instead"
                    : "Manually input an address instead"}
                </button>
              )}
            </div>
          </section>
          <section>
            <h2 className={classes.heading}>When</h2>
            <div className={`${classes.body} ${classes.when}}`}>
              <SingleDatesWidget
                formMethods={formMethods}
                namespace={"dates"}
                hideWhenStatus={true}
              />
              {errors.dates && (
                <div className={classes.formError}>
                  At least one date and time is required
                </div>
              )}
            </div>
          </section>
          <section>
            <h2 className={classes.heading}>Media</h2>
            
            <div className={classes.body}>
              <p>Images must be royalty-free, horizontal and can always be used by the iamsterdam editors.</p>
              <p>Only JPG, 1800x1200 pixels.</p>
              <Controller
                control={control}
                name="files"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div className={classes.mediaWidgetWrapper}>
                    <MediaWidget mediaArray={value} onChange={onChange} />
                  </div>
                )}
                defaultValue={[]}
                rules={{
                  required: true,
                  validate: (files: IntFileItem[]) => {
                    for (const file of files) {
                      if (!file.copyright || !file.copyright.trim()) {
                        return false;
                      }
                    }
                    return true;
                  },
                }}
              />
              {errors.files &&
                // @ts-ignore
                (errors.files.type === "required" ? (
                  <div
                    className={classes.formError}
                    style={{ marginTop: "10px" }}
                  >
                    At least one image or video is required
                  </div>
                ) : (
                  <div
                    className={classes.formError}
                    style={{ marginTop: "10px" }}
                  >
                    Every media item requires copyright information.
                  </div>
                ))}
            </div>
          </section>
          <section>
            <h2 className={classes.heading}>Website</h2>
            <div className={classes.body}>
              <input
                type="text"
                {...register("url", {
                  required: true,
                  pattern:
                    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                })}
                placeholder={"https://"}
              />
              {errors.url &&
                (errors.url.type === "required" ? (
                  <div className={classes.formError}>A website is required</div>
                ) : (
                  <div className={classes.formError}>
                    The provided website link is not valid
                  </div>
                ))}
            </div>
          </section>
          <input type="submit" value={"Submit event"} />
        </form>
      )}
      {isSubmitSuccessful && (
        <div className={classes.submitSuccess}>
          <p>
            Thank you for uploading your event. The editors will check the event
            as soon as possible.
          </p>
          <p>
            If you have any questions, you can contact the editors of
            StartupAmsterdam (
            <a
              href="mailto:startup@amsterdam.nl"
              target="_blank"
              rel="noreferrer"
            >
              startup@amsterdam.nl
            </a>
            ).
          </p>
          <p>
            Yours sincerely,
            <br />
            StartupAmsterdam
          </p>
        </div>
      )}
    </div>
  );
}

export default withRouter(App);
