import React from "react";
import { UseFormRegister } from "react-hook-form";
import classes from "./RadioButtonSelector.module.scss";

const RadioButtonSelector = (props: {
  namespace: string;
  options: string[];
  register: UseFormRegister<any>;
}) => {
  return (
    <div className={classes.RadioButtonSelector}>
      {props.options.map((option: string) => (
        <div key={option} className={classes.optionContainer}>
          <input
            {...props.register(props.namespace, { required: true })}
            type="radio"
            value={option}
            id={option}
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
    </div>
  );
};

export default RadioButtonSelector;
