import { convertEventTypes } from "./convertEventTypes";
import { convertTopics } from "./convertTopics";
import {
  feedFactoryAxios,
  internalToExternal,
} from "@feed-factory/feed-factory.helpers";
import {
  Address,
  InternalEventItemModel,
} from "@feed-factory/feed-factory.models.ndtrc";
import { Lang } from "@feed-factory/feed-factory.models.lang";
import { FormValues } from "../models/FormValues";
import { LocationSelectionType } from "../models/LocationSelectionType";
import { convertEventLocationType } from "./convertEventLocationType";

export const postNewEvent = (
  formValues: FormValues,
  locationSelectionType: LocationSelectionType | null
) => {
  // @ts-ignore
  const newEventInt: InternalEventItemModel = {
    entitytype: "EVENEMENT",
    id: null,
  };

  newEventInt.calendar = {
    singleDates: formValues.dates,
    hasComment: false,
    calendarType: "SINGLEDATES",
  };

  // @ts-ignore
  newEventInt.contactinfo = {
    urls: [
      {
        url: formValues.url,
        targetLanguage: Lang.EN,
        urlServiceType: "general",
      },
    ],
  };

  newEventInt.files = formValues.files;

  // @ts-ignore
  newEventInt.trcItemDetails = {
    en: {
      title: formValues.title,
      shortdescription: formValues.shortDescription,
      longdescription: formValues.longDescription,
    },
  };

  if (locationSelectionType === LocationSelectionType.ManualLocation) {
    const address = formValues.locationAddress;
    address.country = "NL"; // Always assume NL for location in this form
    newEventInt.location = {
      address: address,
      label: formValues.locationLabel,
    };
  } else {
    if (formValues.locationItem) {
      const locTitle =
        formValues.locationItem.trcItemDetails[
          formValues.locationItem.translations?.primaryLanguage as Lang
        ].title;
      newEventInt.location = {
        label: locTitle,
        locationItem: {
          text: locTitle,
          trcid: formValues.locationItem.trcid as string,
          id: formValues.locationItem.id as string,
        },
        address: formValues.locationItem.location?.address as Address,
      };
    }
  }
  newEventInt.keywords = `startupevent;${convertEventTypes(
    formValues.typeOfEvent
  ).join(";")};${convertTopics(formValues.topics).join(
    ";"
  )};${convertEventLocationType(formValues.eventLocationType)}`;

  newEventInt.trcItemCategories = {
    types: [{ catid: "2.3.4" }],
  };

  newEventInt.translations = {
    primaryLanguage: Lang.EN,
    availableLanguages: [Lang.EN],
  };

  return feedFactoryAxios.post("/events", internalToExternal(newEventInt));
};
